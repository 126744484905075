form {
  max-width: $size-body-small;
  margin: 1rem auto;

  &.block {
    max-width: none;
  }

  &.inline-form {
    margin: 0;
    display: inline-block;
  }
}

label, legend {
  display: block;
  font-weight: bolder;

  &.required {
    &::after {
      content: "*";
      color: $color-danger
    }
  }

  .invalid-feedback {
    font-weight: normal;
    color: $color-danger;
    font-size: .9rem;

    .badge {
      font-weight: bold;
      text-transform: uppercase;
    }

  }
}


input, textarea, select {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: $border-default;
  font-size: 1.2em;
  padding: $spacer-sm;
  background: $color-light;

  &:focus {
    background: $color-background;
  }

}

fieldset {
  padding: 0;
  border: none;
}

.form-group {
  margin: $spacer 0;

  small {
    color: $color-text-light;
    font-size: .8rem;
  }

}

.form-check {
  display: grid;
  grid-template-columns: 1em auto;
  grid-gap: 1em;
  align-items: center;
  margin-right: 1em;

  label {
    font-weight: normal;
    color: $color-text-light
  }

  input:checked + label {
    color: $color-text;
  }
}

fieldset .form-check {
  display: inline-grid;
}
