header {
  background: $color-primary-constrast;
  border-bottom: $border-default;
  box-shadow: 0 0 $spacer rgba(0,0,0,0.05);
  text-transform: uppercase;
  z-index: 10000;

  @include width-mobile {
    font-size: .9em;
  }
  @include width-small-mobile {
    font-size: .7em;
  }

  position: sticky;
  top: 0;

  .container {
    display: grid;
    grid-template-columns: auto max-content ;
    align-items: center;
  }

   #logo {
     img {
        height: 3em;
        display: block;
     }
   }

  a {
    color: $color-primary;
    text-decoration: none;
  }

  nav {
    padding: .35em $spacer;

    a {
      margin-left: $spacer-sm;
    }
  }

}

h1, .h1 {

  font-size: 2*$size-font;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  text-align: center;
  line-height: 1.2em;

  @media (max-height: 40*$size-font) {
    margin: .5em auto;
  }

}

.h1 {
  font-size: $size-font;
}

