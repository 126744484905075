
table {

  width: 100%;
  margin: 2rem auto;
  border-collapse: collapse;

  td, th {
    padding: .2rem .5rem;
  }

  tr {
    &:nth-of-type(even) {
      td {
        background: $color-light;
      }
    }
  }

  button, .btn {
    padding: .2rem .5rem;
    display: block;
  }

}
