.jumbo {
  margin: 3rem 0;
  font-size: 1.5rem;
  text-align: center;

}

.landing {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 3rem auto;

  @include width-tablet {
    grid-template-columns: 1fr;
  }

  > * {

    border: $border-default;
    padding: 1rem;

    display: flex;
    flex-direction: column;

    > ol {
      flex-grow: 1;
    }

  }
}

.register-link {
  text-align: center;
  margin: 2rem auto;
}