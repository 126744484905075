main > *,
.container,
.contents {
  max-width: $size-body;
  margin: 0 auto;
  padding: 0 $spacer;
}

.container.wide,
main > .wide, .contents  {
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
  line-height: 1.6em;
}

main {
  min-height: 50vh;
}

a {
  color: $color-primary;
  &:hover {
    color: $color-primary-hover;
  }
}

img {
  max-width: 100%;
}

ol {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding: 0;
  counter-reset: ol;
  max-width: $size-body-small;
  margin: 1rem auto;

  li {
    display: block;
    counter-increment: ol;
    gap: 1rem;
    padding: 0;
    margin: 0;
    position: relative;
    padding-left: 2rem;

    &:before {
      position: absolute;
      left: 0;

      content: counter(ol) '.';
      display: inline-block;
      padding-right: 1rem ;
      color: $color-text-light;
      font-weight: bold;
      text-align: center;
      align-self: flex-start;
      font-size: 1.2em;
    }
  }

}
