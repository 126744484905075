.toast {

  display: block;
  color: $color-text;
  text-decoration: none;
  box-shadow: 0 $spacer-sm/2 $spacer rgba(0,0,0,0.05);
  border: $border-default;
  position: relative;
  top: -$size-border;
  padding: $spacer ;

  b, span {
    display: inline-block;
  }
}

a.toast {
  &:hover {
    color: $color-text;
    background-color: $color-light;
  }
}

.share {

  display: grid;
  grid-template-columns: auto max-content;
  align-items: stretch;
  grid-gap: $size-border;
  margin-top: $spacer-sm;


  input {
    text-align: center;
    border: 0;
    padding: $spacer-sm;
    background: $color-light;
    display: block;
    width: 100%;
    font-size: 1em;
    box-sizing: border-box;
  }

  .links {
    display: flex;
    gap: $size-border;

    a {
      display: inline-block;
      background: $color-text;
      color: $color-primary-constrast;
      text-decoration: none;
      font-size: 1em;
      padding: $spacer-sm $spacer-sm;
    }

  }

}