footer {

  background: $color-primary-constrast;
  margin-top: 6rem;
  font-size: .9rem;

  color: $color-text-light;

  a {
    color: $color-text-light;
  }

  .container {
    border-top: $border-default;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-areas:  'links image'
                          'links copyright';

    .links {
      grid-area: links;
    }

    .copyright {
      grid-area: copyright;
      font-size: .8em;
    }

    .image {
      text-align: right;
      img {
        filter: grayscale(1);
        opacity: .3;
        max-width: 3rem;
      }
    }

  }

  ul {
    padding: 0;
    margin: .5rem 0;
    display: block;

    li {
      display: inline-block;
    }

    li + li::before {
      content: " - ";
      margin: 0 .5rem;
    }

    &:first-of-type {
      font-weight: bold;
    }
  }


  @include width-mobile {
    font-size: .8em;
  }
  @include width-small-mobile {
    font-size: .7em;
  }

}

