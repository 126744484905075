.posts {

  display: grid;
  grid-gap: $spacer-lg;

  > * {

    &:hover {
      .cover {
        border-color: $color-primary;
      }
    }

    a {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: $spacer;
      color: $color-text;
      text-decoration: none;

      @include width-tablet {
        grid-template-columns: 1fr;
        grid-gap: $spacer-sm;

        .cover {
          order:0;
          min-height: 0;
          height: 20vw;
        }
        .content {order:1}
      }

      .cover {
        min-height: $size-font*5;
        background: transparent none center center no-repeat;
        border: $border-default;
        background-size: cover;
      }

      .content {

        display: grid;

        h4 {
          font-size: 1.2 * $size-font;
          margin: 0;
        }
      }
    }

  }

}

.contents {
  display: grid;
  gap: 0;
  grid-template-columns: 1fr;
}

.content {

  &.image {
    img {
      display: block;
    }
  }

  &.text {
    margin: 0;
    padding: $spacer;
  }
}

.content-admin {

  position: relative;

  .content-actions {
    position: absolute;
    right: 0;
    top: 0;

    button {
      padding: .2rem .5rem;
    }

  }
}

.post_infos {
  color: $color-text-light;
  font-size: .8em;
  b, a {
    font-weight: bold;
    color: $color-primary;
  }
}

.like-button {

  .inline-form {
    text-align: center;
    width: 100%;
    max-width: none;

    button {
      min-width: 60%;
    }
  }
}

.covers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $spacer-lg;

  a {

    display: block;
    text-align: center;
    border: $border-default;

    &:hover {
      border-color: $color-primary;
    }

    img {
      display: block;
      width: 100%;
      object-fit: cover;
      height: 25vw;

      @include width-desktop {
        height: 12em;
      }
    }

  }
}