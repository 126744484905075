.alert {

  background-color: $color-primary ;
  color: $color-primary-constrast;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: .5rem;
  font-size: 1.2rem;

  &.success {
    background-color: $color-success ;
    color: $color-success-contrast;
  }

  &.error {
    background-color: $color-danger ;
    color: $color-danger-contrast;
  }

}