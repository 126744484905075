@keyframes heartbeat
{
  0% { transform: scale( .75 ); }
  50% { transform: scale( 1 ); }
  100% { transform: scale( .75 ); }
}

@keyframes rainbow {
  0%{background-position:0% 0}
  50%{background-position:100% 0}
  100%{background-position:0% 0}
}

.btn, button, .btn-list > * {

  display: inline-block;
  border: $border-default;
  border-color: $color-primary;
  background: $color-primary;
  font-weight: bolder;
  text-decoration: none;
  font-size: 1em;
  text-transform: uppercase;
  text-align: center;
  padding: $spacer-sm $spacer;
  color: $color-primary-constrast;
  cursor: pointer;

  &:hover {
    background-color: $color-primary-hover;
    border-color: $color-primary-hover;
    color: $color-primary-constrast;
  }

  &:disabled {
    background: lighten($color-primary, 30%);
    border-color: transparent;
    cursor: not-allowed;
  }

  &.light {
    color: $color-primary;
    background: $color-background;
    border: $border-default;

    &:disabled {
      color: $color-text-light;
    }

    &:not([disabled]) {
      &:hover {
        background-color: $color-light-hover;
        border-color: transparent;
      }
    }
  }

  &.like {
    font-size: 1.4em;
    padding: 1rem 2rem;
    border-radius: 1rem;

    &:not([disabled]) {

      .icon {
        animation-name: heartbeat;
        animation: 1s linear 0s infinite running heartbeat;
      }

      &:hover {
        background: linear-gradient(90deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
        background-size: 1800% auto;
        animation: rainbow 3s infinite;
        border-color: transparent;
      }

    }

  }

  .icon {
    margin-left: .25em;
  }

  svg {
    height: 1em;
    transform: scale(1.2);
  }

}

.btn-list {
  display: grid;
  grid-gap: $spacer-sm;
  max-width: $size-body-small;
  margin: auto;
}

.actions {
  margin: $spacer-lg 0;
}