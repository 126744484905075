@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap");
body, input, textarea, select, button {
  font-family: "Lato", sans-serif;
}

h1, header {
  font-weight: bolder;
}

main > *,
.container,
.contents {
  max-width: 560pt;
  margin: 0 auto;
  padding: 0 14pt;
}

.container.wide,
main > .wide, .contents {
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
  line-height: 1.6em;
}

main {
  min-height: 50vh;
}

a {
  color: #FFBB11;
}
a:hover {
  color: #ffca44;
}

img {
  max-width: 100%;
}

ol {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  counter-reset: ol;
  max-width: 336pt;
  margin: 1rem auto;
}
ol li {
  display: block;
  counter-increment: ol;
  gap: 1rem;
  padding: 0;
  margin: 0;
  position: relative;
  padding-left: 2rem;
}
ol li:before {
  position: absolute;
  left: 0;
  content: counter(ol) ".";
  display: inline-block;
  padding-right: 1rem;
  color: #888;
  font-weight: bold;
  text-align: center;
  align-self: flex-start;
  font-size: 1.2em;
}

header {
  background: #fff;
  border-bottom: 2px solid #eee;
  box-shadow: 0 0 14pt rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  z-index: 10000;
  position: sticky;
  top: 0;
}
@media screen and (max-width: 364pt) {
  header {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 280pt) {
  header {
    font-size: 0.7em;
  }
}
header .container {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
}
header #logo img {
  height: 3em;
  display: block;
}
header a {
  color: #FFBB11;
  text-decoration: none;
}
header nav {
  padding: 0.35em 14pt;
}
header nav a {
  margin-left: 7pt;
}

h1, .h1 {
  font-size: 28pt;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  text-align: center;
  line-height: 1.2em;
}
@media (max-height: 560pt) {
  h1, .h1 {
    margin: 0.5em auto;
  }
}

.h1 {
  font-size: 14pt;
}

footer {
  background: #fff;
  margin-top: 6rem;
  font-size: 0.9rem;
  color: #888;
}
footer a {
  color: #888;
}
footer .container {
  border-top: 2px solid #eee;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas: "links image" "links copyright";
}
footer .container .links {
  grid-area: links;
}
footer .container .copyright {
  grid-area: copyright;
  font-size: 0.8em;
}
footer .container .image {
  text-align: right;
}
footer .container .image img {
  filter: grayscale(1);
  opacity: 0.3;
  max-width: 3rem;
}
footer ul {
  padding: 0;
  margin: 0.5rem 0;
  display: block;
}
footer ul li {
  display: inline-block;
}
footer ul li + li::before {
  content: " - ";
  margin: 0 0.5rem;
}
footer ul:first-of-type {
  font-weight: bold;
}
@media screen and (max-width: 364pt) {
  footer {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 280pt) {
  footer {
    font-size: 0.7em;
  }
}

form {
  max-width: 336pt;
  margin: 1rem auto;
}
form.block {
  max-width: none;
}
form.inline-form {
  margin: 0;
  display: inline-block;
}

label, legend {
  display: block;
  font-weight: bolder;
}
label.required::after, legend.required::after {
  content: "*";
  color: #FF1100;
}
label .invalid-feedback, legend .invalid-feedback {
  font-weight: normal;
  color: #FF1100;
  font-size: 0.9rem;
}
label .invalid-feedback .badge, legend .invalid-feedback .badge {
  font-weight: bold;
  text-transform: uppercase;
}

input, textarea, select {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #eee;
  font-size: 1.2em;
  padding: 7pt;
  background: #eee;
}
input:focus, textarea:focus, select:focus {
  background: #fff;
}

fieldset {
  padding: 0;
  border: none;
}

.form-group {
  margin: 14pt 0;
}
.form-group small {
  color: #888;
  font-size: 0.8rem;
}

.form-check {
  display: grid;
  grid-template-columns: 1em auto;
  grid-gap: 1em;
  align-items: center;
  margin-right: 1em;
}
.form-check label {
  font-weight: normal;
  color: #888;
}
.form-check input:checked + label {
  color: #111;
}

fieldset .form-check {
  display: inline-grid;
}

.alert {
  background-color: #FFBB11;
  color: #fff;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
  font-size: 1.2rem;
}
.alert.success {
  background-color: #00AA11;
  color: #fff;
}
.alert.error {
  background-color: #FF1100;
  color: #fff;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.75);
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 0;
  }
  50% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0% 0;
  }
}
.btn, button, .btn-list > * {
  display: inline-block;
  border: 2px solid #eee;
  border-color: #FFBB11;
  background: #FFBB11;
  font-weight: bolder;
  text-decoration: none;
  font-size: 1em;
  text-transform: uppercase;
  text-align: center;
  padding: 7pt 14pt;
  color: #fff;
  cursor: pointer;
}
.btn:hover, button:hover, .btn-list > *:hover {
  background-color: #ffca44;
  border-color: #ffca44;
  color: #fff;
}
.btn:disabled, button:disabled, .btn-list > *:disabled {
  background: #ffe7aa;
  border-color: transparent;
  cursor: not-allowed;
}
.btn.light, button.light, .btn-list > *.light {
  color: #FFBB11;
  background: #fff;
  border: 2px solid #eee;
}
.btn.light:disabled, button.light:disabled, .btn-list > *.light:disabled {
  color: #888;
}
.btn.light:not([disabled]):hover, button.light:not([disabled]):hover, .btn-list > *.light:not([disabled]):hover {
  background-color: #e9e9e9;
  border-color: transparent;
}
.btn.like, button.like, .btn-list > *.like {
  font-size: 1.4em;
  padding: 1rem 2rem;
  border-radius: 1rem;
}
.btn.like:not([disabled]) .icon, button.like:not([disabled]) .icon, .btn-list > *.like:not([disabled]) .icon {
  animation-name: heartbeat;
  animation: 1s linear 0s infinite running heartbeat;
}
.btn.like:not([disabled]):hover, button.like:not([disabled]):hover, .btn-list > *.like:not([disabled]):hover {
  background: linear-gradient(90deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 1800% auto;
  animation: rainbow 3s infinite;
  border-color: transparent;
}
.btn .icon, button .icon, .btn-list > * .icon {
  margin-left: 0.25em;
}
.btn svg, button svg, .btn-list > * svg {
  height: 1em;
  transform: scale(1.2);
}

.btn-list {
  display: grid;
  grid-gap: 7pt;
  max-width: 336pt;
  margin: auto;
}

.actions {
  margin: 21pt 0;
}

.toast {
  display: block;
  color: #111;
  text-decoration: none;
  box-shadow: 0 3.5pt 14pt rgba(0, 0, 0, 0.05);
  border: 2px solid #eee;
  position: relative;
  top: -2px;
  padding: 14pt;
}
.toast b, .toast span {
  display: inline-block;
}

a.toast:hover {
  color: #111;
  background-color: #eee;
}

.share {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: stretch;
  grid-gap: 2px;
  margin-top: 7pt;
}
.share input {
  text-align: center;
  border: 0;
  padding: 7pt;
  background: #eee;
  display: block;
  width: 100%;
  font-size: 1em;
  box-sizing: border-box;
}
.share .links {
  display: flex;
  gap: 2px;
}
.share .links a {
  display: inline-block;
  background: #111;
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  padding: 7pt 7pt;
}

.plans {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}
@media screen and (max-width: 532pt) {
  .plans {
    grid-template-columns: 1fr;
  }
}
.plans > * {
  padding: 1rem;
  border: 2px solid #eee;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}
.plans > *.current {
  border-color: #FFBB11;
}
.plans > * p {
  flex-grow: 1;
  color: #888;
}
.plans > * .btn {
  display: block;
  width: 100%;
  align-self: flex-end;
}

table {
  width: 100%;
  margin: 2rem auto;
  border-collapse: collapse;
}
table td, table th {
  padding: 0.2rem 0.5rem;
}
table tr:nth-of-type(even) td {
  background: #eee;
}
table button, table .btn {
  padding: 0.2rem 0.5rem;
  display: block;
}

.jumbo {
  margin: 3rem 0;
  font-size: 1.5rem;
  text-align: center;
}

.landing {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 3rem auto;
}
@media screen and (max-width: 532pt) {
  .landing {
    grid-template-columns: 1fr;
  }
}
.landing > * {
  border: 2px solid #eee;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.landing > * > ol {
  flex-grow: 1;
}

.register-link {
  text-align: center;
  margin: 2rem auto;
}

.posts {
  display: grid;
  grid-gap: 21pt;
}
.posts > *:hover .cover {
  border-color: #FFBB11;
}
.posts > * a {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 14pt;
  color: #111;
  text-decoration: none;
}
@media screen and (max-width: 532pt) {
  .posts > * a {
    grid-template-columns: 1fr;
    grid-gap: 7pt;
  }
  .posts > * a .cover {
    order: 0;
    min-height: 0;
    height: 20vw;
  }
  .posts > * a .content {
    order: 1;
  }
}
.posts > * a .cover {
  min-height: 70pt;
  background: transparent none center center no-repeat;
  border: 2px solid #eee;
  background-size: cover;
}
.posts > * a .content {
  display: grid;
}
.posts > * a .content h4 {
  font-size: 16.8pt;
  margin: 0;
}

.contents {
  display: grid;
  gap: 0;
  grid-template-columns: 1fr;
}

.content.image img {
  display: block;
}
.content.text {
  margin: 0;
  padding: 14pt;
}

.content-admin {
  position: relative;
}
.content-admin .content-actions {
  position: absolute;
  right: 0;
  top: 0;
}
.content-admin .content-actions button {
  padding: 0.2rem 0.5rem;
}

.post_infos {
  color: #888;
  font-size: 0.8em;
}
.post_infos b, .post_infos a {
  font-weight: bold;
  color: #FFBB11;
}

.like-button .inline-form {
  text-align: center;
  width: 100%;
  max-width: none;
}
.like-button .inline-form button {
  min-width: 60%;
}

.covers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 21pt;
}
.covers a {
  display: block;
  text-align: center;
  border: 2px solid #eee;
}
.covers a:hover {
  border-color: #FFBB11;
}
.covers a img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 25vw;
}
@media screen and (min-width: 560pt) {
  .covers a img {
    height: 12em;
  }
}