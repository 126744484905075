$color-primary: #FFBB11;
$color-primary-constrast: #fff;
$color-primary-hover: lighten($color-primary, 10%);

$color-success: #00AA11;
$color-success-contrast: #fff;

$color-danger: #FF1100;
$color-danger-contrast: #fff;

$color-text: #111;
$color-text-light: #888;

$color-light: #eee;
$color-background: #fff;
$color-light-hover: darken($color-light, 2%);

$size-font: 14pt;
$size-body: 40*$size-font;
$size-body-small: $size-body*0.6;

$spacer: $size-font;
$spacer-sm: $size-font/2;
$spacer-lg: $size-font*1.5;

$size-border: 2px;
$border-default: $size-border solid $color-light;
