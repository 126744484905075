.plans {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;

  @include width-tablet {
    grid-template-columns: 1fr;
  }

  > * {
    padding: 1rem;
    border: $border-default;

    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    &.current {
      border-color: $color-primary;
    }

    .price {

    }

    p {
      flex-grow: 1;
      color: $color-text-light
    }

    .btn {
      display: block;
      width: 100%;
      align-self: flex-end;
    }
  }

}